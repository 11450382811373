import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['showImageButton', 'imageProperties', 'formParameter', 'cardTextField', 'textLocationInput', 'newCardThumbContainer']

  showFileField(event) {
    this.showImageButtonTarget.classList.add("hidden")
    this.imagePropertiesTargets.forEach( input => input.classList.remove("hidden") )
    this.formParameterTargets.forEach( input => input.setAttribute('name', input.dataset.fieldName) )

    if(this.cardTextFieldTarget.value.length > 0) {
      this.textLocationInputTarget.classList.remove("hidden")
    }
  }
}
