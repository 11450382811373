import { Controller } from 'stimulus'
import consumer from '../channels/consumer'
import CableReady from 'cable_ready'

export default class extends Controller {
  connect () {
    var id = this.data.element.dataset.onlineSessionId

    consumer.subscriptions.create({ channel: "PresentationDisplayChannel", id: id }, {
        connected () {
        },

        received (data) {
          // console.log("PresentationDisplay -- ", data.operations.morph)
          // console.log(data.operations.morph[0]["selector"])
          if(Object.keys(data.operations).length == 0) {
            location.reload();
          } else if (data.cableReady) {
            // console.log(data.operations)
            CableReady.perform(data.operations)
          }
        }
      })
  }
}
