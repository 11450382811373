import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['endSessionModal', 'introductionModal', 'postIntroductionModal', 'addClientModal', 'viewResultsModal']

  closeCurrentModal(event) {
    this.endSessionModalTarget.classList.add('hidden')
    this.introductionModalTarget.classList.add('hidden')
    this.postIntroductionModalTarget.classList.add('hidden')
    this.addClientModalTarget.classList.add('hidden')
    this.viewResultsModalTarget.classList.add('hidden')
  }

  openEndSessionModal(event) {
    this.endSessionModalTarget.classList.remove("hidden")
  }

  openViewResultsModal(event) {
    this.viewResultsModalTarget.classList.remove("hidden")
  }

  openAddClientModal(event) {
    this.addClientModalTarget.classList.remove("hidden")
  }

  closeIntroductionModal(event) {
    this.introductionModalTarget.classList.add("hidden")
    this.addClientModalTarget.classList.add('hidden')
    this.stimulate('TherapistPresentationReflex#close_introduction')
  }

  endSession(event) {
    this.removePreventUnloadListener()
    this.stimulate('TherapistPresentationReflex#end_session')
  }
}
