import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['name', 'status', 'operant', 'patientId', 'programName', 'discriminativeStimulusText', 'trialsPerSession', 'message', 'material']

  connect() {
    super.connect()
  }

  dataset() {
    return {
      patientId: this.patientIdTarget.value,
      targetName: this.nameTarget.value,
      targetOperant: this.operantTarget.value,
      targetStatus: this.statusTarget.value,
      programName: this.programNameTarget.value,
      discriminativeStimulusText: this.discriminativeStimulusTextTarget.value,
      trialsPerSession: this.trialsPerSessionTarget.value,
      cardIds: this.materialTargets.map(card => card.dataset['itemId'])
    }
  }

  validateName(event) {
    let dataset = this.dataset()

    if ([
      dataset.targetName,
      dataset.patientId,
      dataset.targetStatus,
      dataset.targetOperant
    ].every((e) => (e || '').length)) {
      this.stimulate('TargetValidationReflex#validate_unique_name', dataset)
    } else {
      this.messageTarget.innerHTML = ""
    }
  }

  errorMessages(event) {
    this.stimulate('TargetValidationReflex#validate_target', this.dataset())
  }
}
