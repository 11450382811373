import {
  Controller
} from "stimulus"
import {
  GoogleCharts
} from 'google-charts'

export default class extends Controller {
  connect() {
    GoogleCharts.load('current', {
      callback: this.load.bind(this),
      packages: ['timeline']
    });
  }

  load() {
    let url = '/session_presentation/' + this.data.get('session-id') + '/data'
    fetch(url)
      .then(response => response.json())
      .then(json => {
        let dataArray = this.formatData(json)
        this.drawCharts(dataArray)
      })
  }

  determineEndTime(obj) {
    if (obj.hasOwnProperty('endTime')) {
      return new Date(obj.endTime)
    } else {
      return new Date(Number(obj.startTime) + 10000)
    }
  }

  formatEventData(data) {
    if (data.data.length > 0) {
      return data.data.map(a => [(a.name + " (" + data.data.length + ") "), new Date(a.startTime), this.determineEndTime(a)])
    }
  }

  formatData(json) {
    let chartData = {
      sessionStart: json.sessionStart,
      sessionEnd: json.sessionEnd,
      behaviors: json.behaviors.map(a => this.formatEventData(a)).filter(function (e) {
        return e != null;
      }),
      skills: json.skills.map(a => this.formatEventData(a)).filter(function (e) {
        return e != null;
      }),
      topLevel: json.topLevel.map(a => this.formatEventData(a)).filter(function (e) {
        return e != null;
      })
    }

    return chartData
  }

  drawCharts(jsonData) {
    this.drawBehaviorChart(jsonData)
    this.drawSkillsChart(jsonData)
    this.drawTopLevelChart(jsonData)
  }

  drawBehaviorChart(jsonData) {
    var data = new google.visualization.DataTable();
    data.addColumn({
      type: 'string',
      id: 'Behavior'
    });
    data.addColumn({
      type: 'date',
      id: 'Start'
    });
    data.addColumn({
      type: 'date',
      id: 'End'
    });
    data.addRows(jsonData.behaviors.flat())
    var options = {
      width: '100%',
      height: 300,
      hAxis: {
        minValue: new Date(jsonData.sessionStart),
        maxValue: new Date(jsonData.sessionEnd)
      }
    };

    // Instantiate and draw the chart.
    var behaviorChart = new google.visualization.Timeline(document.getElementById('behaviors-timeline'));
    behaviorChart.draw(data, options);
  }

  drawSkillsChart(jsonData) {
    var data = new google.visualization.DataTable();
    data.addColumn({
      type: 'string',
      id: 'Skill'
    });
    data.addColumn({
      type: 'date',
      id: 'Start'
    });
    data.addColumn({
      type: 'date',
      id: 'End'
    });
    data.addRows(jsonData.skills.flat())
    var options = {
      width: '100%',
      height: 300,
      hAxis: {
        minValue: new Date(jsonData.sessionStart),
        maxValue: new Date(jsonData.sessionEnd)
      }
    };

    var skillsChart = new google.visualization.Timeline(document.getElementById('skills-timeline'));
    skillsChart.draw(data, options);
  }

  drawTopLevelChart(jsonData) {
    var data = new google.visualization.DataTable();
    data.addColumn({
      type: 'string',
      id: 'Skill'
    });
    data.addColumn({
      type: 'date',
      id: 'Start'
    });
    data.addColumn({
      type: 'date',
      id: 'End'
    });
    data.addRows(jsonData.topLevel.flat())
    var options = {
      width: '100%',
      height: 300,
      hAxis: {
        minValue: new Date(jsonData.sessionStart),
        maxValue: new Date(jsonData.sessionEnd)
      }
    };

    var topLevelChart = new google.visualization.Timeline(document.getElementById('other-timeline'));
    topLevelChart.draw(data, options);
  }
}
