import { Controller } from "stimulus"
import { GoogleCharts } from 'google-charts'

export default class extends Controller {
  static targets = [ 'displayElement' ]
  static values = { timeInMinutes: String, storageKey: String, dataKey: String }

  loadAndDraw() {
    GoogleCharts.load(this.drawChart.bind(this))
  }

  drawChart() {
    let chart = new google.visualization.LineChart(this.displayElementTarget)
    let retrievedObject = JSON.parse(localStorage.getItem(this.storageKeyValue))
    let resultData = retrievedObject[this.dataKeyValue]
    let range = Number(retrievedObject['duration'] || 'all')

    if(resultData !== undefined){
      chart.draw(this.barChartData(resultData, range), this.barChartOptions(resultData))
    }
  }

  barChartOptions(resultData) {
    // let max_trials = Math.ceil(Math.max(resultData.max.trial_count, resultData.max.trials_per_session))
    // let ticks = this.ticksArray(0, max_trials)

    return {
      backgroundColor: { fill: ''},
      legend: {position: 'none'},
      hAxis: {
        title: '',
        slantedText: true
      },
      vAxis: {
        viewWindow: {
        },
        title: 'Percent',
        baseline: 0,
        ticks: [1,25,50,75,100],
        maxValue: 100,
        minValue: 0,
        gridlines: {
          color: '#fff'
        }
      },
      width: '100%',
      height: '100%',
      // bar: {
      //   groupWidth: '75%'
      // },
      // isStacked: true,
      pointsVisible: true
    }
  }

  ticksArray(min, max) {
    let ticks = []
    let size = max - min
    let increment = 1
    let newTick = min

    if(Math.max(min, max, 1) == 1){ return [0, 1] }

    if (size <= 5){}
    else if( size <= 10 ) {
      increment = 2
    } else if (size <= 30) {
      increment = 5
    } else {
      increment = 10
    }

    while(newTick <= max) {
      ticks.push(newTick)
      newTick += increment
    }

    return ticks
  }

  barChartData(results, range) {
    var data = new google.visualization.DataTable();
    data.addColumn('string', 'Date');
    data.addColumn('number', 'Correct')
    data.addColumn({ type: 'string', role: 'style' })
    data.addColumn({ type: 'string', role: 'annotation' })
    // data.addColumn('number', 'Incorrect')
    // data.addColumn({ type: 'string', role: 'style' })
    // data.addColumn({ type: 'string', role: 'annotation' })
    // data.addColumn('number', 'Displayed')
    // data.addColumn({ type: 'string', role: 'style' })
    // data.addColumn('number', 'Not Run')
    // data.addColumn({ type: 'string', role: 'style' })

    let rows = []
    let result
    Object.keys(results.sessions || {}).forEach((date, index) => {
      if ((typeof range === 'number') && ((Math.abs(new Date() - Date.parse(date)) / ( 60*60*24) / 1000) > range)) {
        return
      }
      result = results.sessions[date] || {}

      rows.push([
        date,
        this.displayPercent(result.correct, result.incorrect),
        '',
        this.displayPercent(result.correct, result.incorrect) + '%',
        // result.incorrect,
        // '',
        // this.displayPercent(result.incorrect, result.correct),
        // Math.max(result.trial_count - (result.correct + result.incorrect), 0),
        // '#ddd',
        // Math.max(result.trials_per_session - result.trial_count, 0),
        // '#eee'
      ])
    })
    data.addRows(rows)

    return data
  }

  displayPercent(numerator, alternate) {
    if( Math.max(numerator || 0, 0) == 0 ) {return 0}

    return Number(((numerator / (numerator + alternate)) * 100).toFixed(0))
  }
}
