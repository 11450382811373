import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["operant", "status", "archived", "source", "filterable"]

  filter(event) {
    let nameFilterTerm = this.sourceTarget.value.toLowerCase()
    let operantFilterTerm = this.operantTarget.value
    let statusFilterTerm = this.hasStatusTarget ? this.statusTarget.value : ''
    let archivedFilterTerm = this.hasArchivedTarget ? this.archivedTarget.checked.toString() : ''

    this.filterableTargets.forEach((el, i) => {
      let namefilterableKey =  el.getAttribute("data-filter-key");
      let operantfilterableKey = el.getAttribute("data-target-templates-operant-filter-key");
      let statusfilterableKey = el.getAttribute("data-target-templates-status-filter-key") || '';
      let archivedfilterableKey = el.getAttribute("data-target-templates-archived-filter-key") || '';

      let hide = !namefilterableKey.includes( nameFilterTerm ) ||
        !operantfilterableKey.includes(operantFilterTerm) ||
        !statusfilterableKey.includes(statusFilterTerm) ||
        !archivedfilterableKey.includes(archivedFilterTerm)

      el.classList.toggle("filter--notFound", hide)
    })
  }

}
