import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ "selected", "source", "validation"]

  validate() {
    if (this.selectedTargets[0].childElementCount >= 4) {
      this.validationTarget.classList.remove('text-white')
      this.validationTarget.classList.add('text-gray-600')
      this.sourceTargets[0].classList.add('pointer-events-none')
      this.sourceTargets[0].classList.add('bg-gray-400')
    } else {
      this.sourceTargets[0].classList.remove('pointer-events-none')
      this.sourceTargets[0].classList.remove('bg-gray-400')
    }
  }

  flip (event) {
    if (this.element.dataset.key < this.element.dataset.resourceSize-1) {
      this.element.dataset.key = (parseInt(this.element.dataset.key) + 1).toString()
    } else {
      this.element.dataset.key = '0'
    }
    this.stimulate('CardFlipReflex#flip', this.element)
  }
}
