import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ "source", "filterable" ]

  filter(event) {
    let lowerCaseFilterTerm = this.sourceTarget.value.toLowerCase()

    this.filterableTargets.forEach((el, i) => {
      let filterableKey =  el.getAttribute("data-filter-key")

      el.classList.toggle("filter--notFound", !filterableKey.includes( lowerCaseFilterTerm ) )
    })
  }

  scrollTo(event) {
    let el = event.currentTarget
    let anchor = el.dataset.anchor

    this.scrollToAnchor(anchor)
  }

}
