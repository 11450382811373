import consumer from "./consumer"
import CableReady from "cable_ready"

var cableChannelId = document.getElementsByTagName('meta').cableChannelId.content;

consumer.subscriptions.create({ channel: "SessionsListChannel", id: cableChannelId }, {
  connected() {
    // Called when the subscription is ready for use on the server
    // console.log("SessionsListChannel connected ", cableChannelId)
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    // console.log("SessionsListChannel disconnected")
  },

  received (data) {
    if (data.cableReady) {
      console.log("data recieved SessionsListChannel")
      CableReady.perform(data.operations)
    }
  }
});
