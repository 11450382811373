import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static values = { itemName: String };
  static targets = ["source", "select", "filterable", "filterButton", "count"]

  connect() {
    this.buttonFilterTerm = ''
    this.count = 0
  }

  filter(event) {
    this.count = 0

    let textFilterTerm = ""
    if (this.hasSourceTarget) textFilterTerm = this.sourceTarget.value.toLowerCase()

    let selectFilterTerm = ""
    if (this.hasSelectTarget) selectFilterTerm = this.selectTarget.value.toLowerCase()

    this.filterableTargets.forEach((el, i) => {
      let hide = false
      if(el.getAttribute("data-filter-hide-unless-selected") == 'true') {
        hide = true
      }

      if(textFilterTerm.length >= 1 || this.buttonFilterTerm.length >= 1 || selectFilterTerm.length >= 1) {
        let textfilterableKey =  (el.getAttribute("data-filter-key") || '').toLowerCase();
        let buttonFilterableKey = (el.getAttribute("data-button-filterable-key") || '').toLowerCase();
        let selectFilterableKey = (el.getAttribute("data-select-filterable-key") || '').toLowerCase();

        hide =
          !((textfilterableKey === textFilterTerm) || textfilterableKey.includes(textFilterTerm)) ||
          !((buttonFilterableKey === this.buttonFilterTerm) || buttonFilterableKey.includes(this.buttonFilterTerm)) ||
          !((selectFilterableKey === selectFilterTerm) || selectFilterableKey.includes(selectFilterTerm))
      }

      el.classList.toggle("filter--notFound", hide)
      if(!hide){ this.count += 1 }
    })

    if(this.hasCountTarget){
      this.countTarget.innerHTML = ("" + this.count + ' ' + this.itemNameValue + (this.count == 1 ? '' : 's'))
    }
  }

  filterButtonClick(event) {
    let term = event.currentTarget.getAttribute('data-button-filterable-term')

    if(term === this.buttonFilterTerm) {
      this.buttonFilterTerm = ''
    } else {
      this.buttonFilterTerm = term
    }

    this.updateFilterButtonsDisplay()
    this.filter()
  }

  updateFilterButtonsDisplay() {
    this.filterButtonTargets.forEach( (el) => {
      if(el.getAttribute('data-button-filterable-term') === this.buttonFilterTerm) {
        el.classList.add("bg-gray-100")
        el.classList.add("hover:bg-gray-100")
      } else {
        el.classList.remove("bg-gray-100")
        el.classList.remove("hover:bg-gray-100")
      }
    })
  }
}
