import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = [ 'tokenBoard' ]

  afterMarkCorrect(element) {
    // add class to token?
  }

  markCorrect(event) {
    this.stimulate('AdvanceCardReflex#mark_correct', event.currentTarget)
  }

  markInCorrect(event) {
    this.stimulate('AdvanceCardReflex#mark_incorrect', event.currentTarget)
  }
}
