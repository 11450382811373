import ApplicationController from './application_controller'
import { flatpickrFunction} from '../packs/application.js'

export default class extends ApplicationController {
  static targets = ['patient', 'baseline', 'current', 'loading']

  selectPatient(event) {
    this.stimulate('StartNewSessionReflex#select_patient', this.patientTarget.value, this.currentEnabled(), this.baselineEnabled())
  }

  currentEnabled() {
    return JSON.parse(this.currentTarget.dataset.enabled)
  }

  baselineEnabled() {
    return JSON.parse(this.baselineTarget.dataset.enabled)
  }

  slideTargetFilter(event) {
    let enabled = JSON.parse(event.currentTarget.dataset.enabled)
    let activeCount = [this.currentEnabled(), this.baselineEnabled()].filter(x => x).length

    if(activeCount === 1 && enabled) { return } // don't disable the last slider if there's only one enabled

    event.currentTarget.dataset.enabled = !enabled
    this.updateSliders()
    this.stimulate('StartNewSessionReflex#filter_targets', this.patientTarget.value, this.currentEnabled(), this.baselineEnabled())
  }

  updateSliders() {
    [this.baselineTarget, this.currentTarget].forEach( target => {
      let button = target.querySelector('button')
      let span = target.querySelector('button span')
      let enabled = JSON.parse(target.dataset.enabled)

      button.classList.toggle("bg-indigo-600", enabled)
      button.classList.toggle("bg-gray-200", !enabled)
      span.classList.toggle("translate-x-5", enabled)
      span.classList.toggle("translate-x-0", !enabled)
    })
  }

  loading() {
    if(this.hasLoadingTarget) this.loadingTarget.classList.remove("hidden")
  }
}
