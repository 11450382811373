import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['show', 'hide', 'error']
  static values = { id: String }

  show(event) {
    if((this.idValue) && (document.getElementById(this.idValue))){
      document.getElementById(this.idValue).classList.remove("hidden")
    } else if( this.hasShowTarget ) {
      this.showTarget.classList.remove("hidden")
    }
  }

  hide(event) {
    if( this.hasErrorTarget ) { this.errorTarget.classList.add("hidden") }

    if((this.idValue) && (document.getElementById(this.idValue))){
      document.getElementById(this.idValue).classList.add("hidden")
    } else if( this.hasShowTarget ) {
      this.showTarget.classList.add("hidden")
    }
  }
}
