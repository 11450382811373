import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ 'choice', 'reveal' ]

  makeChoice(event) {
    this.revealTargets.forEach( reveal => reveal.classList.remove("hidden") )
    this.choiceTargets.forEach( (choice) => {
      if(!choice.contains(event.target)){ choice.classList.add("hidden") }
    })
  }

}
