import ApplicationController  from "./application_controller"


export default class extends ApplicationController {
  static targets = [ 'image' ]
  options = {
    rootMargin: '0px',
    threshold: [0.001, 0.1, 0.9, 0.999],
  }

  observer = new IntersectionObserver(this.intersectionCallback(this), this.options);

  connect() {
    this.trackImages()
  }

  trackImages() {
    this.imageTargets.forEach((target) => {
      this.observer.observe(target);
    })
  }

  intersectionCallback(controller) {
    return (entries) => {
      entries.forEach(entry => {
        if(entry.intersectionRatio > 0) {
          const image = entry.target
          image.setAttribute("src", image.getAttribute("data-url-value"));
          this.observer.unobserve(image)
        }
      })
    }
  }
}
