import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ "selectBox", "formInput"]

  select_resource(event) {
    this.formInputTarget.value = event.currentTarget.dataset.resourceId
    this.element.appendChild(event.currentTarget)
    this.selectBoxTarget.remove()
  }
}
