import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect () {
    super.connect()
  }

  dragStart(event) {
    event.dataTransfer.setData("Targetelement", event.target.getAttribute("data-item-id"));
    event.dataTransfer.effectAllowed = "copy"
  }

  dragOver(event) {
    if(event){ event.preventDefault() }
    let element = event.target.parentElement;
    if(element) {
      element.classList.add("bg-gray-200")
    }
  }

  dragLeave(event) {
    if(event){ event.preventDefault() }
    let element = event.target.parentElement;
    element.classList.remove("bg-gray-200");
  }

  dropOnClient(event) {
    if(event){ event.preventDefault() }
    let element = event.target.parentElement;
    element.dataset.target_template_id = event.dataTransfer.getData("Targetelement");
    setTimeout(() => {
      element.classList.remove("bg-gray-200");
    }, 2000);
  }
}
