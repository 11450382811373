import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['radioInput', 'radioLabel']

  connect () {
    this.checkedClasses = ["bg-indigo-600", "border-transparent", "text-white", "hover:bg-indigo-700"]
    this.unCheckedClasses = ["bg-white", "border-gray-200", "text-gray-900", "hover:bg-gray-50"]
  }

  pickOption(event) {
    event.currentTarget.querySelector("input").checked = true

    this.uncheckAll()
    this.displayChecked(event.currentTarget)
  }

  uncheckAll() {
    this.radioLabelTargets.forEach( input => {
      input.classList.remove(...this.checkedClasses)
      input.classList.add(...this.unCheckedClasses)
    })
  }

  displayChecked(element) {
    element.classList.remove(...this.unCheckedClasses)
    element.classList.add(...this.checkedClasses)
  }
}
