import { Controller } from 'stimulus'
import consumer from '../channels/consumer'
import CableReady from 'cable_ready'

export default class extends Controller {
  connect () {
    var id = this.data.element.dataset.onlineSessionId

    consumer.subscriptions.create({ channel: "PatientViewChannel", id: id }, {
      connected () {
        let event = new CustomEvent("patient-view-channel-loaded")
        window.dispatchEvent(event)
      },

      received (data) {
        if(Object.keys(data.operations).length == 0) {
          location.reload();
        } else if (data.cableReady) {
          // console.log(data.operations.morph)
          // console.log(data.operations)
          CableReady.perform(data.operations)
        }
      }
    })
  }
}
