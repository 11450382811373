import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex'

import { useClickOutside } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['container', 'addNote', 'showNote', 'noteInput']
  static values = { attribute: String, id: String }

  connect() {
    StimulusReflex.register(this)
    this.observeClicks = () => { /* empty */ }
    this.unobserveClicks = () => { /* empty */ }

    [this.observeClicks, this.unobserveClicks] = useClickOutside(this, {element: this.containerTarget, onlyVisible: false})
    return this.unobserveClicks()
  }

  clickOutside(event) {
    if(event){ event.preventDefault() }

    if(!this.element.contains(event.target)) {
      this.saveNote()
      setTimeout(() => { this.unobserveClicks() }, 1)
    }
  }

  saveNote() {
    if(this.hasAddNoteTarget){ this.addNoteTarget.classList.remove("hidden") }
    this.showNoteTarget.classList.remove("hidden")
    this.noteInputTarget.classList.add("hidden")

    this.stimulate('SessionsNotesReflex#add_session_note', this.idValue, this.attributeValue, this.noteInputTarget.value)
  }

  addNote(event) {
    if(this.hasAddNoteTarget){ this.addNoteTarget.classList.add("hidden") }
    this.showNoteTarget.classList.add("hidden")
    this.noteInputTarget.classList.remove("hidden")
    this.noteInputTarget.focus()

    setTimeout(() => { this.observeClicks() }, 1)
  }

}
