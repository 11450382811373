import ApplicationController from './application_controller'
import DailyIframe from "@daily-co/daily-js"

export default class extends ApplicationController {
  static values = { url: String, token: String }
  static targets = ['selfVideo', 'selfAudio', 'selfMic', 'patientVideo', 'patientAudio', 'patientMic']

  connect() {
    super.connect()

    if(!window.callObject) { window.callObject = DailyIframe.createCallObject() }
    this.attachHandlers(window.callObject)
  }

  disconnect() {
    window.callObject.leave();
    window.callObject.destroy();
  }

  attachHandlers() {
    window.callObject
      .on('track-started',        this.logVideoEvent.bind(this))
      .on('track-stopped',        this.logVideoEvent.bind(this))
      .on('left-meeting',         this.logVideoEvent.bind(this))
      .on('loading',              this.logVideoEvent.bind(this))
      .on('loaded',               this.logVideoEvent.bind(this))
      .on('started-camera',       this.logVideoEvent.bind(this))
      .on('joining-meeting',      this.logVideoEvent.bind(this))
      .on('joined-meeting',       this.logVideoEvent.bind(this))
      .on('participant-joined',   this.logVideoEvent.bind(this))
      .on('participant-updated',  this.logVideoEvent.bind(this))
      .on('participant-left',     this.logVideoEvent.bind(this))
      .on('camera-error',         this.logVideoEvent.bind(this))
      .on('camera-error',         this.updateDisplay.bind(this))
      .on('error',                this.logVideoEvent.bind(this))
      .on('error',                this.displayVideoError.bind(this))
      .on('network-connection',   this.logVideoEvent.bind(this))
  }

  joinCall(event) {
    if(!window.callEnabled) {return}

    if(window.callObject.meetingState() === 'new'){
      window.callObject.join({ url: this.urlValue, token: this.tokenValue });
    }
  }

  mediaElementFor(event) {
    return // needs to find media elements by participant id and track.kind perhaps (new struture with less stimulus)

    let mediaElement
    if(!event.track) {return}
    if(!event.participant.local && (!this.hasPatientAudioTarget || !this.hasPatientVideoTarget)) {return}

    if ( event.track.kind === 'audio' ) {
      mediaElement = event.participant.local ? this.selfAudioTarget : this.patientAudioTarget
      mediaElement.muted = event.participant.local ? true : false
    } else {
      mediaElement = event.participant.local ? this.selfVideoTarget : this.patientVideoTarget
    }

    return mediaElement
  }

  logVideoEvent(event) {
    console.log(event)
  }

  displayVideoError(event) {
    // find element by dataset.participantId instead of stimulus
    let mediaElement = this.mediaElementFor(event)
    if(!mediaElement) {return}

    this.updateDisplay(event, mediaElement)
  }

  updateDisplay(event, mediaElement = null) {
    if(!mediaElement) { mediaElement = this.selfVideoTarget }

    switch (window.callObject.meetingState()) {
      case 'error':
        this.showErrors(mediaElement, 'Call error - please reload')
        break;
      default:
        this.hideErrors(mediaElement)
        break;
    }

    switch (event.action) {
      case 'track-started':
        mediaElement.parentElement.getElementsByTagName('svg').forEach(icon => {
          if (icon.classList.contains('mic-on')) { icon.classList.remove('hidden') }
          if (icon.classList.contains('mic-off')) { icon.classList.add('hidden') }
        })
        this.hideErrors(mediaElement)
        break;
      case 'camera-error':
        this.showErrors(mediaElement, 'Camera error - please enable your camera to join this session')
        break;
      case 'error':
        this.showErrors(mediaElement, 'Error')
        break;
      default:
        break;
    }
  }

  hideErrors(mediaElement) {
    let errorElement =  mediaElement.parentElement.getElementsByClassName('video-error')[0]
    window.parentElement = mediaElement.parentElement
    errorElement.classList.add('hidden')
  }

  showErrors(mediaElement, message=null) {
    let errorElement =  mediaElement.parentElement.getElementsByClassName('video-error')[0]

    errorElement.classList.remove('hidden')
    if(message) {errorElement.innerHTML = message}
  }
}
