import { Controller } from "stimulus"

// handles interface changes for different materials presentation options
// for Targets and TargetTemplates

export default class extends Controller {
  static targets = ["materialsStep", "cards", "correctCard", "multipleExemplarCards", "distractorCards", "availableCards",
    "randomizedCards", "multipleExemplars", "messyDisplay", "includeDistractors",
    "operantSelect", "newCardThumbContainer"]

  connect() {
    this.showMaterialsInterface(this.operantSelectTarget.value)
  }

  get interfaceInputs() {
    return {
      tact:                     ['correct_card'],
      listener_respond_single:  ['correct_card'],
      tact_multiple_exemplar:   ['correct_card', 'multiple_exemplars', 'randomize'],
      listener_respond:         ['correct_card', 'distractor_cards', 'randomize', 'messy'],
      match:                    ['correct_card', 'distractor_cards', 'randomize', 'messy']
    }
  }

  operantSelect(event) {
    this.showMaterialsInterface(event.target.value)
  }

  optionsChanged(event) {
    this.showMaterialsInterface(this.operantSelectTarget.value)
  }

  showMaterialsInterface(operant) {
    let version = this.interfaceVersion(operant)
    let inputs = this.interfaceInputs[version] || []

    if (inputs.length > 0) {
      this.cardsTarget.classList.remove("hidden")
      if(this.hasMaterialsStepTarget){ this.materialsStepTarget.disabled = false }
    } else {
      this.cardsTarget.classList.add("hidden")
      if(this.hasMaterialsStepTarget){ this.materialsStepTarget.disabled = true }
    }

    if (inputs.includes('correct_card')) {
      if(this.hasCorrectCardTarget){ this.correctCardTarget.classList.remove("hidden") }
    } else {
      if(this.hasCorrectCardTarget){ this.correctCardTarget.classList.add("hidden") }
    }

    if (inputs.includes('distractor_cards')) {
      if(this.hasDistractorCardsTarget){ this.distractorCardsTarget.classList.remove("hidden") }
    } else {
      if(this.hasDistractorCardsTarget){ this.distractorCardsTarget.classList.add("hidden") }
    }

    if (inputs.includes('multiple_exemplars')) {
      if(this.hasMultipleExemplarCardsTarget){ this.multipleExemplarCardsTarget.classList.remove("hidden") }
    } else {
      if(this.hasMultipleExemplarCardsTarget){ this.multipleExemplarCardsTarget.classList.add("hidden") }
    }

    if (inputs.includes('randomize')) {
      if(this.hasRandomizedCardsTarget){ this.randomizedCardsTarget.classList.remove("hidden") }
    } else {
      if(this.hasRandomizedCardsTarget){ this.randomizedCardsTarget.classList.add("hidden") }
    }

    if (inputs.includes('messy')) {
      if(this.hasMessyDisplayTarget){ this.messyDisplayTarget.classList.remove("hidden") }
    } else {
      if(this.hasMessyDisplayTarget){ this.messyDisplayTarget.classList.add("hidden") }
    }

    if (operant === 'tact') {
      if(this.hasMultipleExemplarsTarget){ this.multipleExemplarsTarget.classList.remove("hidden") }
    } else {
      if(this.hasMultipleExemplarsTarget){ this.multipleExemplarsTarget.classList.add("hidden") }
    }

    if (operant === 'listener_respond') {
      if(this.hasIncludeDistractorsTarget){ this.includeDistractorsTarget.classList.remove("hidden") }
    } else {
      if(this.hasIncludeDistractorsTarget){ this.includeDistractorsTarget.classList.add("hidden") }
    }
  }

  interfaceVersion(operant) {
    let version = operant

    if(this.singleListenerResponding(operant)) { version += '_single'}
    if(this.showMultipleExemplars(operant)) { version += '_multiple_exemplar'}

    return version
  }

  singleListenerResponding(operant) {
    if (operant !== 'listener_respond') return false
    if (!this.hasIncludeDistractorsTarget) return false
    // if (this.includeDistractorsTarget.classList.contains('hidden')) return false

    var input = this.includeDistractorsTarget.querySelectorAll('input[type=checkbox]')[0]
    return !input.checked
  }

  showMultipleExemplars(operant) {
    if (operant !== 'tact') return false
    if (!this.hasMultipleExemplarsTarget) return false
    // if (this.multipleExemplarsTarget.classList.contains('hidden')) return false

    var input = this.multipleExemplarsTarget.querySelectorAll('input[type=checkbox]')[0]
    return input.checked
  }

  addNewCardToTarget(event) {
    if(this.hasNewCardThumbContainerTarget) {
      let card = this.newCardThumbContainerTarget.children[0]
      let node = this.addNewCardToCardPicker(card)

      setTimeout(() => {
        node.click()
      }, 100);
    }
  }

  cancelNewCard(element) {
    if(this.hasNewCardThumbContainerTarget) {
      let newCard = this.newCardThumbContainerTarget.children[0]
      this.addNewCardToCardPicker(newCard)
    }
  }

  addNewCardToCardPicker(element) {
    const fragment = document
      .createRange()
      .createContextualFragment(element.outerHTML);

    this.availableCardsTarget.appendChild(fragment)

    return this.availableCardsTarget.children[this.availableCardsTarget.children.length - 1]
  }
}
