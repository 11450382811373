import ApplicationController from './application_controller.js'

export default class extends ApplicationController {
  static values = { sessionId: String, productionEnv: Boolean }

  connect() {
    super.connect()
    this.intervalSet = null;
    this.intervalDelay = this.productionEnvValue ? 3000 : 10000
  }

  patient_joined_presentation() {
    this.stimulate('PatientPresentationMonitorReflex#patient_joined_presentation')
  }

  patientScreenResolution(event){
    if(this.intervalSet == null) {
      setInterval(this.storeScreenResolution.bind(this, this.sessionIdValue), this.intervalDelay)
      this.intervalSet = true
    }
  }

  storeScreenResolution(onlineSessionId) {
    this.stimulate('PatientScreenResolutionReflex#store_screen_resolution', window.innerHeight, window.innerWidth, onlineSessionId)
  }
}
