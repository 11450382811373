import { Controller } from "stimulus"
import { GoogleCharts } from 'google-charts'

export default class extends Controller {
  static targets = [ 'displayElement', 'menu' ]
  static values = { timeInMinutes: String, storageKey: String, dataKey: String }

  connect() {
    this.scalar = 'elapsed'
  }

  loadAndDraw() {
    // material charts - clean visuals
    // GoogleCharts.load(() =>{
    //   google.charts.load('current', {'packages':['line']});
    //   google.charts.setOnLoadCallback(this.drawChart.bind(this));
    // })

    // default google charts:
    GoogleCharts.load(this.drawChart.bind(this))
  }

  drawChart() {
    // material charts
    // let lineChart = new google.charts.Line(this.displayElementTarget)
    let lineChart = new google.visualization.LineChart(this.displayElementTarget)
    let retrievedObject = JSON.parse(localStorage.getItem(this.storageKeyValue))
    let resultData = retrievedObject[this.dataKeyValue]
    let days_range = Number(retrievedObject['duration'] || 'all')

    if(resultData !== undefined){
      lineChart.draw(this.lineChartData(resultData, this.scalar, days_range), this.lineChartOptions(resultData, this.scalar))
    }
  }

  lineChartOptions(resultData, scalar) {
    // material charts
    // return google.charts.Line.convertOptions({
    let options, ticks

    options = {
      backgroundColor: { fill: ''},
      legend: {position: 'none'},
      hAxis: {
        title: '',
        slantedText: true
      },
      vAxis: {
        viewWindow: {
        },
        baseline: 0,
        minValue: 0,
        gridlines: {
          color: '#fff'
        }
      },
      width: '100%',
      height: '100%',
      pointsVisible: true
    }

    if( scalar == 'elapsed' ) {
      ticks = this.ticksArray(Math.floor(resultData.min[scalar]), Math.ceil(resultData.max[scalar]))
      options['vAxis']['title'] = 'Total Duration per Session'
      options['vAxis']['ticks'] = ticks
      options['vAxis']['maxValue'] = ticks[ticks.length - 1]
    }

    return options
  }

  ticksArray(min, max) {
    let ticks = []
    let size = max - min
    let increment = 1
    let newTick = min

    if(Math.max(min, max, 1) == 1){ return [0, 1] }

    if (size <= 5){
      if (min > 10) {
        newTick = increment = Math.floor(min/4)
      }
    }
    else if( size <= 10 ) {
      increment = 2
    } else if (size <= 30) {
      increment = 5
    } else {
      increment = 10
    }

    while(newTick <= max) {
      ticks.push(newTick)
      newTick += increment
    }

    return ticks
  }

  lineChartData(results, scalar, days_range) {
    let data = new google.visualization.DataTable();
    data.addColumn('string', 'Date');
    data.addColumn('number', results.name)

    let rows = []
    Object.keys(results.sessions || {}).forEach((date, index) => {
      if ((typeof days_range === 'number') && ((Math.abs(new Date() - Date.parse(date)) / ( 60*60*24) / 1000) > days_range)) {
        return
      }
      rows.push([date, (results.sessions[date] || {})[scalar]])
    })
    data.addRows(rows)

    return data
  }

  toggleMenu() {
    this.menuTarget.classList.toggle('hidden')
  }

  updateScalar(event) {
    this.scalar = event.target.getAttribute("data-scalar")
    this.toggleMenu()
    this.drawChart()
  }
}
