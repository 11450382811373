import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = [ 'form', 'alternateSubmitLink', 'originalForm', 'flashErrors', 'resetField' ]
  static values = { morph: String };

  disconnect() {
    this.disableTargets().forEach( element => {
      element.disabled = false
    })
  }

  disableTargets() {
    return this.alternateSubmitLinkTargets.concat(
      this.hasFormTarget ? Array.from(this.formTarget.querySelectorAll("input[type=submit]")) : []
    ).flat()
  }

  onNavigationDisableSubmit(event) {
    this.disableTargets().forEach( element => {
      element.disabled = true
    })
  }

  onPostErrorShowFlash(event) {
    let [data, status, xhr] = event.detail;
    this.flashErrorsTarget.innerHTML = xhr.response;
  }

  onPostSuccessShowFlash(event) {
    let [data, status, xhr] = event.detail;
    this.flashErrorsTarget.innerHTML = xhr.response;
  }

  onPostSuccessMorph(event) {
    this.stimulate(this.morphValue)
  }

  onPostSuccessRedirect(event) {
    let [data, status, xhr] = event.detail;
    eval(xhr.response);
  }

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    const fragment = document
      .createRange()
      .createContextualFragment(xhr.response);

    let original = this.element.replaceChild(fragment.firstChild.children[0], this.formTarget)

    if(!this.hasOriginalFormTarget){
      this.element.appendChild(original)
      original.classList.add("hidden")
      original.setAttribute('data-ajax-form-target', 'originalForm')
    }
  }

  revertOriginal(event) {
    if(this.hasOriginalFormTarget) {
      this.element.replaceChild(this.originalFormTarget, this.formTarget)
      this.originalFormTarget.classList.remove("hidden")
      this.originalFormTarget.setAttribute('data-ajax-form-target', 'form')
      this.resetFieldTargets.forEach( input => input.value = '' )
    }
  }
}
