import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ "icon", "noticeBanner" ]

  close_banner() {
    this.noticeBannerTarget.classList.add("hidden")
    this.iconTarget.classList.remove("hidden")

    if (this.iconTarget.dataset.bannerStatus == "false") {
      this.stimulate('BannerReflex#set_close_banner_value')
    }
  }

  show_banner(event) {
    this.noticeBannerTarget.classList.remove("hidden")
    this.iconTarget.classList.add("hidden")
  }
}
