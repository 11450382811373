import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["root", "show", "addClass", "link"]

  connect () {
    let match = this.linkTargets[0].getAttribute('href') + "$"

    if(window.location.pathname.match(match)) {
      this.showRoot()
      this.show()
      this.addClasses()
    }

    let match_alt = this.linkTargets[0].dataset.navHighlightAlternateHref
    if(match_alt && window.location.pathname.match(match_alt)) {
      this.showRoot()
      this.show()
      this.addClasses()
    }

    let match2 = this.element.dataset.navHighlightShow
    if(match2 && window.location.pathname.match(match2)) {
      this.showRoot()
    }

    let match3 = this.element.dataset.navHighlightParent
    if(match3 && window.location.pathname.match(match3)) {
      this.show()
      this.addClasses()
    }
  }

  show() {
    this.showTargets.forEach((el) => {
      el.classList.remove('hidden')
    })
  }

  showRoot() {
    this.rootTargets.forEach((el) => {
      el.classList.remove('hidden')
    })
  }

  addClasses() {
    this.addClassTargets.forEach((el) => {
      el.classList.add(el.dataset.navHighlightClass)
    })
  }
}
