import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = [ 'currentSlide', 'navControl', 'showControls', 'presentationWindow' ]

  connect() {
    StimulusReflex.register(this)
  }

  selectTargetInSession(event) {
    this.stimulate('OnlineSessionNavReflex#set_next_target', event.currentTarget)
  }

  setMasteredTargetNext(event) {
    this.stimulate('OnlineSessionNavReflex#set_mastered_target_next', event.currentTarget)
  }



  beforeSetMasteredTargetNextAndPlay(event) {
    this.currentSlideTarget.classList.add("hidden")
  }
  setMasteredTargetNextAndPlay(event) {
    this.stimulate('OnlineSessionNavReflex#set_mastered_target_next_and_play', event.currentTarget)
  }

  beforeSelectTargetInSessionAndPlay(event) {
    this.currentSlideTarget.classList.add("hidden")
  }
  selectTargetInSessionAndPlay(event) {
    this.stimulate('OnlineSessionNavReflex#set_next_target_and_play', event.currentTarget)
  }

  beforePlayNext(event) {
    this.currentSlideTarget.classList.add("hidden")
  }
  playNext(event) {
    this.stimulate('OnlineSessionNavReflex#play_next', event.currentTarget)
  }

  hideSidebar(event) {
    this.stimulate('OnlineSessionNavReflex#hide_sidebar')
  }

  beforeToggleStop(element) {
    // these are working if we call the reflex from stimulus instead of calling it from data-reflex="click->OnlineSessionNavReflex#toggle_stop"
    this.currentSlideTarget.classList.add("hidden")
  }

  toggleHighlightCorrect(event) {
    this.stimulate('OnlineSessionNavReflex#toggle_highlight_correct')
  }

  toggleStop(event) {
    this.stimulate('OnlineSessionNavReflex#toggle_stop')
  }

  afterReflex(element) {
    if(this.hasCurrentSlideTarget) { this.currentSlideTarget.classList.remove("hidden") }
  }

  showSidebar(element) {
    this.stimulate('OnlineSessionNavReflex#show_sidebar')
  }

  openControlsWindow(element) {
    this.stimulate('OnlineSessionNavReflex#open_controls_window')
  }

  afterOpenControlsWindow(element, reflex) {
    window.open(document.URL.replace(/\/therapist_view.*$/, "") + "/therapist_controls", '_blank', 'location=yes,height=720,width=690')
    // window.open(document.URL.replace(/\/therapist_view.*$/, "") + "/therapist_controls", '_blank', 'location=yes,height=720,width=690,scrollbars=no,status=yes')
  }

  closeControlsWindow(element) {
    this.stimulate('OnlineSessionNavReflex#close_controls_window')
  }

  afterCloseControlsWindow(element, reflex) {
    // separate controls window disabled - bugs around the therapist's session closing when they press "endSession"
    // window.close()
  }
}
