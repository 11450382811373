import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['inputBox', 'addButton', 'name', 'type']

  close() {
    this.inputBoxTarget.classList.add("hidden")
    this.addButtonTarget.classList.remove("hidden")
  }

  showSampleInputBox(event) {
    event.preventDefault()
    event.stopPropagation()
    this.addButtonTarget.classList.add("hidden")
    this.inputBoxTarget.classList.remove("hidden")

    this.nameTarget.placeholder = event.currentTarget.dataset.placeholder
    this.typeTarget.value = event.currentTarget.dataset.type
    event.currentTarget.parentElement.remove()
  }

  showInputBox() {
    this.addButtonTarget.classList.add("hidden")
    this.inputBoxTarget.classList.remove("hidden")

    this.nameTarget.placeholder = this.nameTarget.dataset.originalPlaceholder
    this.typeTarget.value = ''
  }

  getData(event) {
    return {
      'resourceId': event.currentTarget.dataset.resourceId,
      'name': this.nameTarget.value,
      'type': this.typeTarget.value
    }
  }

  create_behavior(event) {
    this.stimulate("AddWidgetInSessionReflex#add_behavior", this.getData(event))
  }

  create_skill(event) {
    this.stimulate("AddWidgetInSessionReflex#add_skill", this.getData(event))
  }
}
