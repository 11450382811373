import ApplicationController from './application_controller.js'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = [ 'idSelected', 'displaySelected' ]

  pick(event) {
    this.idSelectedTarget.value = event.currentTarget.dataset.singleSelectId
    this.displaySelectedTarget.replaceChildren(event.currentTarget.cloneNode(true))
  }
}
