import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['name', 'month']

  inputData() {
    let data =  {
      "name": this.nameTarget.value,
      "month": this.monthTarget.value
    }
    this.stimulate('SessionsMonthFilterReflex#filterData', data)
  }

}