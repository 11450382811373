require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("alpinejs")

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import "controllers"
import '../stylesheets/application.scss'

import '@fortawesome/fontawesome-free/js/all'

require("trix")
require("@rails/actiontext")

import flatpickr from "flatpickr"

document.addEventListener("turbolinks:load", () => {
  flatpickrFunction()
})

export function flatpickrFunction() {
  flatpickr("[data-behavior='flatpickr']", {
    altInput: true,
    dateFormat: "Y-m-d",
    enableTime: true,
    altInput: true,
    minDate: "today",
    altFormat: "F j, Y at h:i K",
    dateFormat: "Y-m-d H:i",
    minuteIncrement: 30
  }),
  flatpickr("[data-behavior='flatpickr-birthdate']", {
    altInput: true,
    dateFormat: "Y-m-d",
    enableTime: false,
    altInput: true,
    minDate: "1900-1-1",
    maxDate: "today",
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
  })
}
