import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
/* This is your ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  connect () {
    this.element[this.identifier] = this
    StimulusReflex.register(this)
  }

  loading() {
    let loading = new CustomEvent("modal-loading")
    window.dispatchEvent(loading)
  }

  loaded() {
    let loaded = new CustomEvent("modal-loaded")
    window.dispatchEvent(loaded)
  }

  removePreventUnloadListener() {
    window.removeEventListener('beforeunload', this.preventUnload)
  }

  preventUnload (e) {
    e.preventDefault();
    e.returnValue = '';
  }

  scrollToAnchor(anchor=location.hash) {
    if (anchor == '') return

    location.hash = ''
    location.hash = anchor
  }

  currentEmojiPicker() {
    return document.querySelector('.emoji-picker__wrapper[style*="display: block"]');
  }

  /* Application-wide lifecycle methods
   *
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Example#demo"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  beforeReflex (element, reflex, noop, reflexId) {
    this.benchmark = performance.now()
  }

  finalizeReflex (element, reflex, noop, reflexId) {
  }

  afterReflex (element, reflex, noop, reflexId) {
    this.afterReflexEvents(reflex);
    // console.log(reflex, `${(performance.now() - this.benchmark).toFixed(0)}ms`)
  }

  reflexSuccess (element, reflex, noop, reflexId) {
    // show success message etc...
  }

  reflexError (element, reflex, error, reflexId) {
    // show error message etc...
  }

  afterReflexEvents(reflex) {
    switch (reflex) {
      case 'TherapistPresentationSettingsReflex#refresh':
        window.dispatchEvent(new CustomEvent('refresh-after-settings'))
        break;
      default:
        break;
    }
  }
}
