import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['target', 'targetForm', 'material', 'materialsContainer', 'attrPreview',
    'materialsPreview', 'programsSuggested', 'programsSuggestedLabel', 'patientPrograms']
  static values = { item: String }

  connect() {
    super.connect()
  }

  buildTargetWithTemplate(event) {
    this.loading()

    this.stimulate('CreateTargetReflex#build_target_with_template', this.targetTarget.dataset)
  }

  buildTargetWithTemplateSuccess(element, reflex, noop, reflexId) {
    this.loaded()
  }

  // triggered on select-program-step event
  updateProgramStep() {
    this.updateAttributesSummary()
    this.updateMaterialsSummary()
  }

  updateAttributesSummary(){
    this.attrPreviewTargets.forEach((attr) => {
      let name = attr.dataset['attrName']
      let input = this.targetFormTarget.elements[name]

      if(input) attr.innerHTML = input.selectedOptions ? input.selectedOptions[0].text : input.value
    })
  }

  materialsForSession() {
    return this.materialsContainerTargets.filter((materialsContainer) => {
      return !(
        materialsContainer.classList.contains('hidden') ||
        materialsContainer.parentElement.classList.contains('hidden') ||
        materialsContainer.parentElement.parentElement.classList.contains('hidden')
      )
    })
  }

  updateMaterialsSummary() {
    let patientProgramNames = [...this.patientProgramsTarget.children].map(program => program.dataset['programName'].toLowerCase())
    this.materialsPreviewTarget.innerHTML = ''

    this.materialsForSession().forEach((inSessionGroup) => {
      Array.from(inSessionGroup.querySelectorAll('.deck-card')).map((card) => {
        this.cloneMaterialForSummary(card, patientProgramNames)
      });
    })

    if (this.programsSuggestedTarget.children.length > 0) {
      this.programsSuggestedLabelTarget.classList.remove("hidden")
    } else {
      this.programsSuggestedLabelTarget.classList.add("hidden")
    }
  }

  cloneMaterialForSummary(item, patientProgramNames) {
    let itemClone = item.cloneNode(true)
    itemClone.setAttribute('data-target-validation-target', 'material')
    itemClone.setAttribute('data-action', '')

    this.materialsPreviewTarget.appendChild(itemClone)

    Object.values(item.getElementsByClassName('program-suggestions')).forEach((program) => {
      let programClone = program.cloneNode(true)

      if(
        (this.programsSuggestedTarget.getElementsByClassName(programClone.id).length == 0) &&
        !(patientProgramNames.includes(programClone.dataset['programName'].toLowerCase()))
      ) {
        this.programsSuggestedTarget.appendChild(programClone)
      }
    })
  }

  selectProgram(event) {
    this.targetFormTarget.elements['target[program_assignment]'].value = event.currentTarget.dataset['programName']
  }
}
