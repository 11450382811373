import { Controller } from "stimulus"

// moves cards and materials on the page between active and inactive areas of the UI
// and prepares active objects for form submission
// for Targets, TargetTemplates, and SessionResources

export default class extends Controller {
  static targets = [ "form", "item", 'error', 'flashErrors', 'hiddenInput', 'available',
    'inSession', 'correctCard', 'multipleExemplarCards', 'distractorCards', 'inFieldCards', 'fieldSizeDisplay', 'fieldIncludesCorrect' ]
  static values = { itemType: String, paramBase: String }

  clickItem(event) {
    if ( this.inSessionTargets.length > 1 ) { throw 'materials relationship not found'; }

    if(this.inSessionTarget.contains(event.currentTarget)){
      this.availableTarget.appendChild(event.currentTarget);
    } else {
      this.inSessionTarget.appendChild(event.currentTarget);
    }
  }

  materialDestination(material) {
    let intoSession = this.materialsForSession()

    if( this.availableTarget.contains(material) ){
      // material moving from the list of options into the session
      if( intoSession.length === 1 ) {
        return intoSession[0]
      } else if(intoSession[0].querySelectorAll('.deck-card').length == 0) {
        return intoSession[0]
      } else {
        return intoSession[1]
      }
    } else {
      // material moving from the session back into the list of options
      return this.availableTarget
    }
  }

  addMaterial(event) {
    let card = event.currentTarget
    let destination = this.materialDestination(card)

    if ( (destination === this.correctCardTarget) || (destination === this.multipleExemplarCardsTarget) ) {
      card.setAttribute('data-item-type-value', 'correct_cards')
    } else {
      card.removeAttribute('data-item-type-value')
    }

    if (destination === this.correctCardTarget) {
      this.correctCardTarget.querySelectorAll('.deck-card').forEach((oldCard) => {
        this.availableTarget.appendChild(oldCard)
      })
    }

    destination.appendChild(card)
    this.updateFieldSizeDisplay()
  }

  updateFieldSizeDisplay(event) {
    if(this.hasFieldSizeDisplayTarget && this.hasFieldIncludesCorrectTarget) {

      this.fieldSizeDisplayTarget.innerHTML = this.fieldSize()

      if (this.correctCardTarget.childElementCount == 0) {
        this.fieldIncludesCorrectTarget.classList.add("hidden")
      } else {
        this.fieldIncludesCorrectTarget.classList.remove("hidden")
      }
    }
  }

  fieldSize() {
    let field = 0
    this.materialsForSession().forEach((inSession) => {
      if (this.inFieldCardsTargets.includes(inSession)) {
        field += inSession.childElementCount
      }
    })

    return field
  }

  materialsForSession() {
    return this.inSessionTargets.filter((inSession) => {
      return !(
        inSession.classList.contains('hidden') ||
        inSession.parentElement.classList.contains('hidden') ||
        inSession.parentElement.parentElement.classList.contains('hidden')
      )
    })
  }

  prepareParams(submitEvent) {
    this.materialsForSession().forEach((inSessionGroup) => {
      Array.from(inSessionGroup.querySelectorAll('.deck-card')).map(card => this.addItemHiddenInput(card));
    })
  }

  addItemHiddenInput(item) {
    let paramBase = this.paramBaseValue || 'online_session'
    let itemType = item.getAttribute("data-item-type-value") || this.itemTypeValue
    let input = document.createElement('input');

    input.setAttribute('name', paramBase + "["+ itemType + "][]");
    input.setAttribute('value', item.getAttribute("data-item-id"));
    input.setAttribute('type', "hidden")
    input.setAttribute('data-session-items-target', "hiddenInput")
    this.formTarget.appendChild(input);
  }

  removeHiddenInputs() {
    this.hiddenInputTargets.forEach((input) => {
      input.parentNode.removeChild(input);
    })
  }

  onPostErrorShowFlash(event) {
    let [data, status, xhr] = event.detail;
    this.flashErrorsTarget.innerHTML = xhr.response;
  }
}
