import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["countDownField"]

  typeChange(event) {
    if(event.target.value == 'count-down'){
      this.countDownFieldTargets.forEach( input => {
        input.classList.remove("hidden")
        input.disabled = false
      })
    } else {
      this.countDownFieldTargets.forEach( input => {
        input.classList.add("hidden")
        input.disabled = true
      })
    }
  }
}
