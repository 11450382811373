import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['step', 'loading']

  connect() {
    this.controllerName = 'steps'
    this.currentStep = 0
    this.loading = false
  }

  advance() {
    this.currentStep = Math.min(this.currentStep + 1, this.stepTargets.length -1)
    this.displayStep()
  }

  advanceTo(event) {
    let toStep = event.currentTarget.dataset['advanceTo']
    if( (toStep || '').length == 0 ) return

    let stepIndex = this.stepTargets.findIndex((element) => element.dataset["stepName"] === toStep)
    if( stepIndex < 0 ) return

    this.currentStep = stepIndex
    this.displayStep()
  }

  back() {
    if(this.currentStep > 0) { this.currentStep = this.currentStep - 1 }
    this.displayStep()
  }

  displayStep() {
    this.displayLoading()

    this.stepTargets.forEach((step, index) => {
      if(this.findController(step) !== this.element) { return }

      if((index == this.currentStep) && !this.isLoading()){
        step.classList.remove('hidden')
        this.fireEntryEvent(step)
      } else {
        step.classList.add('hidden')
      }
    })
  }

  displayLoading() {
    if (!this.hasLoadingTarget) { return }

    if(this.loading) {
      this.loadingTarget.classList.remove('hidden')
    } else {
      this.loadingTarget.classList.add('hidden')
    }
  }

  isLoading() {
    return this.hasLoadingTarget && this.loading
  }

  loadingEvent() {
    this.loading = true
    this.displayLoading()
  }

  loadedEvent() {
    this.loading = false
    this.advance()
  }

  fireEntryEvent(step) {
    let eventName = step.dataset['fireEntryEvent']
    if( (eventName || '').length == 0 ) return

    let event = new CustomEvent(eventName)
    window.dispatchEvent(event)
  }

  findController(el) {
    while ((el = el.parentNode) && (el.dataset['controller'] || '').indexOf(this.controllerName) < 0);
    return el;
  }
}
