import ApplicationController from './application_controller'

export default class extends ApplicationController {
  enableVideo(event) {
    this.removePreventUnloadListener()
    this.stimulate('TherapistVideoReflex#enable_video')
  }

  disableVideo(event) {
    this.removePreventUnloadListener()
    this.stimulate('TherapistVideoReflex#disable_video')
  }
}
