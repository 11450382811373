import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checkbox", "input", "element" ]

  connect() {
    this.toggle()
  }

  toggle(event=null) {
    if(!this.hasCheckboxTarget) {return}

    if(this.checkboxTarget.checked){
      this.deactivate()
    } else {
      this.activate()
    }
  }

  deactivate(event) {
    this.inputTargets.forEach((input) => {
      input.disabled = true
    })

    this.elementTargets.forEach((element) =>{
      element.classList.add("opacity-50")
    })
  }

  activate(event) {
    this.inputTargets.forEach((input) => {
      input.disabled = false
    })

    this.elementTargets.forEach((element) =>{
      element.classList.remove("opacity-50")
    })
  }
}
