import ApplicationController from './application_controller'
import { useClickOutside } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ["presentationButton", "settingsInterface"]

  connect() {
    this.observeClicks = () => { /* empty */ }
    this.unobserveClicks = () => { /* empty */ }

    [this.observeClicks, this.unobserveClicks] = useClickOutside(this)
    return this.unobserveClicks()
  }

  toggleSettings() {
    if(this.settingsInterfaceTarget.classList.contains('hidden')){
      this.openSettings()
    } else {
      this.closeSettings()
    }
  }

  openSettings() {
    if(this.hasPresentationButtonTarget){ this.presentationButtonTarget.classList.add("hidden") }
    this.settingsInterfaceTarget.classList.remove("hidden")
    setTimeout(() => { this.observeClicks() }, 1)
  }

  closeSettings() {
    if(this.hasPresentationButtonTarget){ this.presentationButtonTarget.classList.remove("hidden") }
    this.settingsInterfaceTarget.classList.add("hidden")
    this.unobserveClicks()
  }

  clickOutside(event) {
    if(event){ event.preventDefault() }

    if(this.element.contains(event.target)) return
    if(this.currentEmojiPicker() && this.currentEmojiPicker().contains(event.target)) return

    this.closeSettings()
  }
}
