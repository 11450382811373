import {
  Controller
} from "stimulus"

import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ["toggle", "show", "hide", "iconRight", "iconDown", "iconRotate", "focusAfterShow"]
  static values = { ignoreOutsideClicks: Boolean, stopPropOnClick: Boolean}

  connect() {
    this.observeClicks = () => { /* empty */ }
    this.unobserveClicks = () => { /* empty */ }

    if(!this.ignoreOutsideClicksValue) {
      [this.observeClicks, this.unobserveClicks] = useClickOutside(this)
      return this.unobserveClicks()
    }
  }

  clickOutside(event) {
    if(event){ event.preventDefault() }

    if(!this.element.contains(event.target)) { this.hide() }
  }

  showElement(el) {
    if(el.classList.contains(this.hiddenClass)){
      el.velocity("fadeOut", { duration: 10 })
      el.classList.remove(this.hiddenClass)
      el.classList.add(this.toggledClass)
      el.velocity("fadeIn", { duration: 500 })
    }
  }

  hideElement(el, fast=false) {
    if(fast){
      el.classList.add(this.hiddenClass)
    } else {
      el.velocity("fadeOut", { duration: 500 })
      setTimeout(() => {
        el.classList.add(this.hiddenClass)
      }, 500)
    }
  }

  show(event) {
    if(event){ event.preventDefault() }

    this.showTargets.forEach((el) => {
      this.showElement(el)
    })

    this.iconRightTargets[0] && this.iconRightTargets[0].classList.add("hidden")
    this.iconDownTargets[0] && this.iconDownTargets[0].classList.remove("hidden")
    if(this.hasIconRotateTarget) this.iconRotateTarget.classList.add('rotate-90')


    if(this.hasFocusAfterShowTarget) { this.focusAfterShowTarget.focus() }
    setTimeout(() => { this.observeClicks() }, 1)
  }

  hide(event) {
    if(event){ event.preventDefault() }

    this.hideTargets.forEach((el) => {
      this.hideElement(el)
    })

    this.iconRightTargets[0] && this.iconRightTargets[0].classList.remove("hidden")
    this.iconDownTargets[0] && this.iconDownTargets[0].classList.add("hidden")
    if(this.hasIconRotateTarget) this.iconRotateTarget.classList.remove('rotate-90')

    this.unobserveClicks()
  }

  toggle(event) {
    if(event){
      event.preventDefault()
      if (this.stopPropOnClickValue) { event.stopPropagation(); }
    }

    let visible = this.toggleTargets.find(element => !element.classList.contains(this.hiddenClass));

    if( visible ) {
      this.hide(event)
    } else {
      this.show(event)
    }
  }

  toggleElement(){
    let togglesToShow = this.element.querySelectorAll('.' + this.hiddenClass)
    let togglesToHide = this.element.querySelectorAll('.' + this.toggledClass)

    togglesToShow.forEach((el) => { this.showElement(el) })

    togglesToHide.forEach((el) => { this.hideElement(el, true) })
  }

  get hiddenClass() {
    if (this.data.get("hidden-class")) {
      return this.data.get("hidden-class")
    } else {
      return "hidden"
    }
  }

  get toggledClass() {
    if (this.data.get("toggled-class")) {
      return this.data.get("toggled-class")
    } else {
      return "canHide"
    }
  }
}
