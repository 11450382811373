import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'duration' ]


  connect() {
    this.storageKey = this.element.getAttribute("data-storage-key")
  }

  setDuration(event) {
    let graphing = JSON.parse(localStorage.getItem(this.storageKey))
    graphing['duration'] = event.target.getAttribute("data-graph-duration")
    localStorage.setItem(this.storageKey, JSON.stringify(graphing))

    this.updateDisplay(event.target)
    this.updateGraphs()
  }

  updateGraphs() {
    let event = new CustomEvent("graph-all")
    window.dispatchEvent(event)
  }

  updateDisplay(target) {
    this.durationTargets.forEach((el) => {
      el.classList.remove("text-purple-700")
    })
    target.classList.add("text-purple-700")
  }
}
