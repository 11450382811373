import ApplicationController from './application_controller'
import Logger from '../logger'

export default class extends ApplicationController {
  static targets = [ "dragOver" ]

  connect () {
    super.connect()
  }

  dragStart(event) {
    event.dataTransfer.effectAllowed = "copy"
  }

  dragOver(event) {
    if(event){ event.preventDefault() }
    let parent = event.target.parentElement;

    if( this.hasDragOverTarget ) {
      this.dragOverTarget.classList.add("bg-gray-200")
    } else if(parent) {
      parent.classList.add("bg-gray-200")
    }
  }

  dragLeave(event) {
    if(event){ event.preventDefault() }
    let parent = event.target.parentElement;

    if( this.hasDragOverTarget ) {
      this.dragOverTarget.classList.remove("bg-gray-200")
    } else if(parent) {
      parent.classList.remove("bg-gray-200")
    }
  }

  dropOnClient(event) {
    if(event){ event.preventDefault() }
    let element = event.target.parentElement;
    setTimeout(() => {
      element.classList.remove("bg-gray-200");
    }, 2000);
  }

  dropOnSession(event) {
    if(event){ event.preventDefault() }
    let element = event.target.parentElement;
    setTimeout(() => {
      element.classList.remove("bg-gray-200");
    }, 2000);
  }

  dropOnCard(event) {
    Logger.log("dropOnCard", event.type, event)

    setTimeout(() => {
      this.dragOverTarget.classList.remove("bg-gray-200")
    }, 2000);

    this.stimulate('TrackMouseReflex#dragging_drop', this.dragOverTarget)
  }
}
