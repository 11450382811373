import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['copyText', 'confirmation']

  connect() {
    super.connect()

    if(this.hasConfirmationTarget) {
      this.oldConfirmationText = this.confirmationTarget.innerHTML
      this.restoreConfirmationTimeoutId = null
    }
  }

  copy(event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.copyTextTarget.textContent);

    if(this.hasConfirmationTarget) {
      this.confirmationTarget.innerHTML = "Copied!"
      clearTimeout(this.restoreConfirmationTimeoutId)

      this.restoreConfirmationTimeoutId = setTimeout(() => {
        this.confirmationTarget.innerHTML = this.oldConfirmationText
      }, 2000);
    }
  }
}
