import Sentry from '../sentry-init'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'

const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))
StimulusReflex.initialize(application, { consumer })
if (process.env.RAILS_ENV.match(/development|test/)) StimulusReflex.debug = true

if (process.env.RAILS_ENV === 'development') {
  // import('radiolabel').then(Radiolabel =>
  //   application.register('radiolabel', Radiolabel.default)
  // )
}
