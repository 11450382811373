import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "filter", "filterable", "button", "menu" ]

  connect() {
    document.addEventListener('click', this.handleCloseMenuClick)
    return () => {
      document.removeEventListener('click', this.handleCloseMenuClick);
    };
  }

  handleCloseMenuClick = (e) => {
    if(this.menuIsOpen() && !this.menuTarget.contains(e.target) && !this.buttonTarget.contains(e.target)){
      this.toggle();
    }
  };

  toggle(event) {
    if( !this.menuIsOpen() ){
      this.menuTarget.classList.remove('hidden')
      this.menuTarget.classList.remove('opacity-0')
      this.menuTarget.classList.remove('-translate-y-1')
      this.menuTarget.classList.add('opacity-100')
      this.menuTarget.classList.add('translate-y-0')
    } else {
      this.menuTarget.classList.add('opacity-0')
      this.menuTarget.classList.add('-translate-y-1')
      this.menuTarget.classList.remove('opacity-100')
      this.menuTarget.classList.remove('translate-y-0')
      this.filter()
      setTimeout(() => { this.menuTarget.classList.add('hidden') }, 200)
    }
  }

  menuIsOpen() {
    return !this.menuTarget.classList.contains("opacity-0")
  }

  filter() {
    let filterTerm = ""
    document.querySelectorAll(".showIdentifier").forEach((el) => {
      filterTerm += " " + el.getAttribute("data-filter-key")
    })

    this.filterableTargets.forEach((el, i) => {
      let filterableKey =  el.getAttribute("data-filter-key")

      el.classList.toggle("filter--notFound", !filterTerm.includes( filterableKey ) )
    })

    this.updateGraphs()
  }

  updateGraphs() {
    let event = new CustomEvent("graph-all")
    window.dispatchEvent(event)
  }

  identifierFilterClick(event) {
    let filter = event.target.nodeName == "SPAN" ? event.target.parentNode : event.target
    this.toggleIdentifier(filter)
  }

  toggleIdentifier(filter) {
    if(filter.classList.contains("showIdentifier")){
      this.hideIdentifier(filter)
    } else {
      this.showIdentifier(filter)
    }
  }

  toggleGroup(event) {
    let groupFilter = "div[data-filter-group='" + event.target.getAttribute('data-filter-group') + "']"
    if(document.querySelectorAll(groupFilter + ".showIdentifier").length > 0) {
      document.querySelectorAll(groupFilter).forEach((el, i) => {
        this.hideIdentifier(el)
      })
    } else {
      document.querySelectorAll(groupFilter).forEach((el, i) => {
        this.showIdentifier(el)
      })
    }
  }

  hideIdentifier(element) {
    element.classList.remove('showIdentifier')
    element.classList.remove('text-gray-900')
    element.classList.add('text-gray-400')
  }

  showIdentifier(element) {
    element.classList.add('showIdentifier')
    element.classList.add('text-gray-900')
    element.classList.remove('text-gray-400')
  }
}
