import { Controller } from "stimulus"

export default class extends Controller {
  static values = { patientId: String }

  connect() {
    this.storageKey = this.element.getAttribute("data-storage-key")
    this.loadPatientSummary()
  }

  loadPatientSummary() {
    let targetId = this.element.getAttribute("data-summary-data-target-id")
    let url = '/patients/' + this.patientIdValue + '/results'
    if (targetId) { url += "?target_id=" + targetId}

    fetch(url, {
      headers:  {
        "Content-Type": "application/json",
        "Accept": "application/json"
      }
    }).then(response => {
      return response.json()
    }).then((json) => {
      localStorage.setItem(this.storageKey, JSON.stringify(json))

      let event = new CustomEvent("patient-summary-loaded")
      window.dispatchEvent(event)
    })
  }
}
