import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["formInput", "option", 'display'];
  currentOption = null

  connect() {
    super.connect()
  }

  filter(event) {
    let lowerCaseFilterTerm = event.target.value.toLowerCase()

    this.optionTargets.forEach((el, i) => {
      let filterableKey =  el.getAttribute("data-filter-key")

      el.classList.toggle("filter--notFound", !filterableKey.includes( lowerCaseFilterTerm ) )
    })
  }

  selectOption(event) {
    this.formInputTarget.value = event.target.dataset.value
    this.displayTarget.innerHTML = event.target.dataset.value

    if (this.currentOption) { this.currentOption.classList.remove('bg-blue-600') }
    event.target.classList.add('bg-blue-600');
    this.currentOption = event.target
  }
}
