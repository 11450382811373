import {
  Controller
} from "stimulus"

export default class extends Controller {
  click(event) {
    var hamburger = new CustomEvent('hamburger:click', {bubbles: true})
    document.dispatchEvent(hamburger);
  }
}
