import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["card"];
  static values = { url: String };

  connect() {
    this.requested = false
    this.showing = false
  }

  show() {
    this.showing = true

    if (this.hasCardTarget) {
      this.cardTarget.classList.remove("hidden");
    } else if( !this.requested ) {
      this.requested = true
      fetch(this.urlValue)
        .then((r) => r.text())
        .then((html) => {
          const fragment = document
            .createRange()
            .createContextualFragment(html);

          this.element.appendChild(fragment);
          if ( !this.showing ) {
            this.hide()
          }
        });
    }
  }

  mouseleaveHide() {
    this.showing = false
    this.hide()
  }

  hide() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.add("hidden");
    }
  }

  disconnect() {
    if (this.hasCardTarget) {
      this.cardTarget.remove();
    }
  }
}
