import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['clear']

  clear() {
    this.showTargets.forEach((el) => {
      el.reset()
    })
  }

  change() {
  }

  nullSubmit(e) {
    e.preventDefault()
  }

  submit (e) {
      e.preventDefault()
      let notify = document.querySelector(this.data.get('notification'))
      this.stimulate(this.data.get('reflex'))
      .then(() => {
        this.element.reset()
        notify.notification.notifyOnSuccess()
      })
      .catch(payload => {
        // to do - add messages
        notify.notification.notifyOnError()
    })
  }
}
