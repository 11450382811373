import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["left", "right", "onlineSession"]

  connect() {
    this.left()
  }

  left(event) {
    this.shift("left")
  }

  right(event) {
    this.shift("right")
  }


  shift(direction) {
    let totalSessions = this.onlineSessionTargets.length
    let visibleSessionIndex = this.onlineSessionTargets.findIndex((onlineSession) => { return getComputedStyle(onlineSession).display !== "none" })
    let visibleSessionCount = this.onlineSessionTargets.filter(onlineSession => getComputedStyle(onlineSession).display !== "none" ).length
    let newVisibleIndex

    if((direction === 'left') && (visibleSessionIndex === 0)) {
      return
    }

    if((direction === 'right') && ((visibleSessionIndex + visibleSessionCount) === totalSessions)) {
      return
    }

    if(direction === 'left') {
      newVisibleIndex = Math.max(visibleSessionIndex - visibleSessionCount, 0);
    } else if(direction === 'right') {
      newVisibleIndex = Math.min(visibleSessionIndex + visibleSessionCount, totalSessions - visibleSessionCount);
    };

    this.hideAndShow(newVisibleIndex)
  }

  hideAndShow(newVisibleIndex) {
    let classMapping = ['', '', 'hidden md:block', 'hidden lg:block', 'hidden lg:block']
    let newSessionTarget

    this.onlineSessionTargets.forEach((onlineSession) => {
      onlineSession.className += ' animate-fade';
    });

    setTimeout(() => {
      this.onlineSessionTargets.forEach((onlineSession) => {
        onlineSession.setAttribute('class', 'hidden')
      });

      [0,1,2,3,4].forEach((i) => {
        newSessionTarget = this.onlineSessionTargets[newVisibleIndex + i]
        if(newSessionTarget) {
          newSessionTarget.setAttribute('class', classMapping[i] + ' animate-fade-in')
        }
      })
    }, 400);

    setTimeout(() => {
      [0,1,2,3,4].forEach((i) => {
        newSessionTarget = this.onlineSessionTargets[newVisibleIndex + i]
        if(newSessionTarget) {
          newSessionTarget.setAttribute('class', classMapping[i])
        }
      })
    }, 800);
  }
}
