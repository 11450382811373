import ApplicationController from './application_controller'

import ahoy from "ahoy.js"

export default class extends ApplicationController {
  connect() {

    document.addEventListener('stimulus-reflex:before', event => {
      this.limitEvents(event, (event) => {
        ahoy.track(event.detail.reflex, {
          visit_token: this.ahoyVisitToken(),
          online_session_id: this.onlineSessionId(),
          visitor_role: this.visitorRole(),
          reflex_id: event.detail.reflexId,
        });
      });

      // event.target // the element that triggered the Reflex (may not be the same as controller.element)
      // event.detail.reflex // the name of the invoked Reflex
      // event.detail.reflexId // the UUID4 or developer-provided unique identifier for each Reflex
      // event.target.reflexData[event.detail.reflexId] // the data payload that will be delivered to the server
      // event.target.reflexData[event.detail.reflexId].attrs // the serialized form data for this Reflex
      // // empty?
      // event.target.reflexData[event.detail.reflexId].params // the serialized form data for this Reflex
      // event.detail.controller // the controller that invoked the stimuluate method
    })

    document.addEventListener('stimulus-reflex:after', event => {
      this.limitEvents(event, (event) => {
        fetch("/ahoy/eventcomplete", {
          method: "POST",
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
            reflex_id: event.detail.reflexId
          })
        })
      });
    })
  }

  limitEvents(event, trackingFunction) {
    if(!(event.detail.reflex || '').includes("TrackMouse#mouse_move")){
      trackingFunction(event);
    }
  }

  ahoyVisitToken() {
    return document.getElementsByTagName('meta').AhoyVisit.content
  }

  visitorRole() {
    switch (true) {
      case /online_session_data/.test(window.location.pathname):
        return 'therapist_review'
        break;
      case /therapist_view/.test(window.location.pathname):
        return 'therapist'
        break;
      case /patient_view/.test(window.location.pathname):
        return 'patient'
        break;
    }
  }

  onlineSessionId() {
    const regexp = /session_presentation\/([a-f0-9\-]*)\//
    const match = window.location.pathname.match(regexp);
    if(match){
      return match[1]
    }
  }
}
