import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['form', 'requiredInput', 'submit', 'nameInput', 'emailInput', 'joinListInput']

  updateSampleUser(event) {
    this.stimulate('TherapistPresentationReflex#update_sample_user', {
      join_email_list: this.joinListInputTarget.checked.toString(),
      full_name: this.nameInputTarget.value,
      email: this.emailInputTarget.value,
    }, this.formTarget.dataset.context)

    event.preventDefault() // important - prevents the form from submitting and reloading the page
  }

  validate(event) {
    let isValid = true;

    this.requiredInputTargets.forEach((field) => {
      if (!field.value.trim()) {
        isValid = false;
      }
    })

    if (isValid) {
      this.submitTarget.disabled = false
    }
  }

}
