import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: 'https://50f2b91989d24d6799c9cf9471b6f2c3@o20527.ingest.sentry.io/6150578',

  // Alternatively, use `process.env.npm_package_version` for a dynamic release version
  // if your build tool supports it.
  // release: "pulse-survey@2.3.12",
  release: process.env.npm_package_version,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  enabled: (process.env.NODE_ENV || 'development') !== 'development',
});

export default Sentry
