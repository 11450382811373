import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["operant", "checkbox", "openIcon", "picker", "text"]

  toggleTargetPicker(event) {
    if( this.pickerTarget.classList.contains("hidden") ){
      this.pickerTarget.classList.remove('hidden')
      this.openIconTarget.classList.add('rotate-90')
    } else {
      this.pickerTarget.classList.add('hidden')
      this.openIconTarget.classList.remove('rotate-90')
    }
    event.stopPropagation()
  }

  toggleOperantPicker() {
    let selected = this.checkboxTargets.filter(el => el.checked).length
    let enabled = this.pickerTarget.classList.contains("hidden")
    enabled = enabled || (selected == 0) || (selected == this.checkboxTargets.length)

    if( enabled ){
      if( this.checkboxTargets.find(element => element.checked) ) {
        this.checkboxTargets.forEach(el => el.checked = false)
      } else {
        this.checkboxTargets.forEach(el => el.checked = true)
      }

      this.updateDisplay()
    }
  }

  updateDisplay(event) {
    if( this.checkboxTargets.find(element => element.checked) ) {
      this.operantTarget.classList.add('active')
      this.updateText(this.checkboxTargets.filter(el => el.checked).length, "selected")
    } else {
      this.operantTarget.classList.remove('active')
      this.updateText(this.checkboxTargets.length)
    }

    if(event){event.stopPropagation()}
  }

  updateText(count, extraText = '') {
    let text = count + " target"
    if( count != 1 ) { text += 's' }
    text += ' ' + extraText

    this.textTarget.innerHTML = text
  }

}
