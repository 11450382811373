import ApplicationController from './application_controller'
import DailyIframe from "@daily-co/daily-js"

export default class extends ApplicationController {
  static targets = ['selfVideo', 'selfAudio', 'selfMic']

  connect() {
    super.connect()
    if(!window.callObject) { window.callObject = DailyIframe.createCallObject() }

    this.attachHandlers()
    this.therapistMuted = false
    this.participantId = null
  }

  disconnect() {
    window.callObject.off('track-started', this.trackStarted.bind(this))
    window.callObject.off('track-stopped', this.trackStopped.bind(this))
  }

  attachHandlers() {
    window.callObject.on('track-started', this.trackStarted.bind(this))
    window.callObject.on('track-stopped', this.trackStopped.bind(this))
    window.callEnabled = true
  }

  trackStarted(event) {
    if(!event.track) {return}
    if(!event.participant.local) {return}

    let mediaElement = (event.track.kind === 'audio') ? this.selfAudioTarget : this.selfVideoTarget
    mediaElement.muted = true
    mediaElement.srcObject = new MediaStream([event.track]);
    this.participantId = event.participant.session_id
  }

  trackStopped(event) {
    if(!event.track)                  {return}
    if(event.track.kind !== 'audio')  {return}
    if(!event.participant)            {return}
    if(!event.participant.local)      {return}

    this.selfAudioTarget.muted = true
  }

  toggleTherapistMic(event) {
    let icons = event.target.getElementsByTagName('svg')

    this.therapistMuted = !this.therapistMuted

    this.setAudioTrack(this.therapistMuted ? 'muted' : 'live')
    window.callObject.updateParticipant(this.participantId, { setAudio: !this.therapistMuted})
  }

  setAudioTrack(status) {
    if(status === 'live') {
      Array.prototype.forEach.call(this.selfMicTarget.getElementsByTagName('svg'), icon => {
        if (icon.classList.contains('mic-on')) { icon.classList.add('hidden') }
        if (icon.classList.contains('mic-off')) { icon.classList.remove('hidden') }
      });
      this.stimulate('VideoReflex#set_data', 'therapist_audio_enabled', true)
    } else if(status === 'muted') {
      Array.prototype.forEach.call(this.selfMicTarget.getElementsByTagName('svg'), icon => {
        if (icon.classList.contains('mic-on')) { icon.classList.remove('hidden') }
        if (icon.classList.contains('mic-off')) { icon.classList.add('hidden') }
      });
      this.stimulate('VideoReflex#set_data', 'therapist_audio_enabled', false)
    }
  }
}
