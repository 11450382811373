import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["toggleable", "toggleHidden", "toggleButton"]

  connect() {
  }

  toggle(event) {
    if(event){ event.preventDefault() }
    let textContent

    this.toggleableTargets.forEach((el) => {
      textContent = el.textContent
      el.textContent = el.getAttribute("data-toggle-hidden")
      el.setAttribute("data-toggle-hidden", textContent)
    })

    this.toggleHiddenTargets.forEach((el) => {
      el.classList.toggle('hidden')
    })

    this.toggleButtonTargets.forEach((el) => {
      if( el.classList.contains("ring-indigo-500") ){
        el.classList.remove("ring-indigo-500")
        el.classList.remove("border-indigo-500")
        Object.values(el.getElementsByTagName('svg')).forEach((svg) => svg.classList.remove("text-indigo-700"))
      } else {
        el.classList.add("ring-indigo-500")
        el.classList.add("border-indigo-500")
        Object.values(el.getElementsByTagName('svg')).forEach((svg) => svg.classList.add("text-indigo-700"))
      }
    })

  }
}
