import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex'
import { EmojiButton } from '@joeattardi/emoji-button'

export default class extends ApplicationController {
  static targets = [ "button" ]

  trigger = document.querySelector('#emoji-trigger');

  connect() {
    StimulusReflex.register(this)
    this.picker = new EmojiButton()
    this.picker.on('emoji', emoji => {
      this.changeEmoji(emoji.emoji)
    })
  }

  changeEmoji(emoji){
    this.stimulate('TokenBoard#change_reinforcer_emoji', emoji)
  }

  toggle(event) {
    event.preventDefault()
    try { this.picker.togglePicker(event.target)}
    catch {
      this.connect()
      this.picker.togglePicker(event.target)
    }
  }
}
