import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ 'option' ]

  connect() {
    Object.values(this.element.options).forEach( option => { if(option.value == window.location.pathname){option.selected = true} } )
  }

  followLink(event) {
    let link = event.currentTarget.value


    if(link.length == 0) {return}

    if(link[0] === '?') {
      let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + link;
      window.history.pushState({ path: newurl }, '', newurl);
    } else {
      window.location.pathname = link
    }
  }
}
