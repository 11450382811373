import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['color', 'errors']

  notifyOnSuccess() {
    this.colorTargets.forEach(target => this.highlightSuccess(target))
  }

  notifyOnError() {
    this.colorTargets.forEach(target => this.highlightError(target))
  }

  highlightSuccess (element) {
    element.velocity({ scale: 1.05, backgroundColor: 'green' }, 1000).
    velocity({ scale: 1, backgroundColor: '#fff' }, 500)
  }

  highlightError (element) {
    element.velocity({ scale: 1.05, backgroundColor: 'red' }, 1000).
    velocity({ scale: 1, backgroundColor: '#fff' }, 500)
  }
}
