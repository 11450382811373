import ApplicationController from './application_controller'
import DailyIframe from "@daily-co/daily-js"

export default class extends ApplicationController {
  static targets = ['therapistVideo', 'therapistAudio', 'therapistSpeaker', 'therapistMute']

  connect() {
    super.connect()
    if(!window.callObject) { window.callObject = DailyIframe.createCallObject() }

    this.attachHandlers()
    this.participantId = null
  }

  disconnect() {
    window.callObject.off('track-started', this.trackStarted.bind(this))
    window.callObject.off('track-stopped', this.trackStopped.bind(this))
  }

  attachHandlers() {
    window.callObject.on('track-started', this.trackStarted.bind(this))
    window.callObject.on('track-stopped', this.trackStopped.bind(this))
    window.callEnabled = true
  }

  trackStarted(event) {
    if(!event.track) {return}
    if(event.participant.local) {return}

    let mediaElement = (event.track.kind === 'audio') ? this.therapistAudioTarget : this.therapistVideoTarget
    // mediaElement.muted = false // start with the setting rendered into the element on page load

    if(event.track.kind === 'audio') {
      this.therapistSpeakerTarget.classList.remove("hidden")
      this.therapistMuteTarget.classList.add("hidden")
    }

    mediaElement.srcObject = new MediaStream([event.track]);
    this.participantId = event.participant.session_id
  }

  trackStopped(event) {
    if(!event.track)                  {return}
    if(event.track.kind !== 'audio')  {return}
    if(!event.participant)            {return}
    if(event.participant.local)       {return}

    this.therapistAudioTarget.muted = true
    this.therapistSpeakerTarget.classList.add("hidden")
    this.therapistMuteTarget.classList.remove("hidden")
  }
}
