import { Controller } from 'stimulus'
import throttle from 'lodash/throttle'
import StimulusReflex from 'stimulus_reflex'
import Logger from '../logger'

export default class extends Controller {
  static targets = ["draggableCard", "placeholder", "dropOn"];

  connect () {
    StimulusReflex.register(this)

    this.xlocation = 0
    this.ylocation = 0
    this.dragging = false
    this.touching = false

    this.throttleMousemove = throttle(this.mousemove, 300)
    window.addEventListener('mousemove', this.throttleMousemove.bind(this), { passive: true })
    window.addEventListener('mousedown', this.mousedown.bind(this), { passive: true })

    window.addEventListener('dragstart', this.dragstart.bind(this), { passive: true })
    this.throttleDrag = throttle(this.drag, 300)
    window.addEventListener('drag', this.throttleDrag.bind(this), { passive: true })
    window.addEventListener('dragend', this.dragend.bind(this), { passive: true })

    window.addEventListener('touchstart', this.touchstart.bind(this), { passive: true })
    this.throttleTouchmove = throttle(this.touchmove, 100)
    window.addEventListener('touchmove', this.throttleTouchmove.bind(this), { passive: true })
    window.addEventListener('touchend', this.touchend.bind(this), { passive: true })
    this.throttleTouchDisplay = throttle(this.touchdisplay, 300)
  }

  disconnect () {
    window.removeEventListener('mousemove', this.throttleMousemove.bind(this), { passive: true })
    window.removeEventListener('mousedown', this.mousedown.bind(this), { passive: true })

    window.removeEventListener('dragstart',   this.dragstart.bind(this), { passive: true })
    window.removeEventListener('drag',      this.throttleDrag.bind(this), { passive: true })
    window.removeEventListener('dragend',   this.dragend.bind(this), { passive: true })

    window.removeEventListener('touchstart',   this.touchstart.bind(this), { passive: true })
    window.removeEventListener('touchmove', this.throttleTouchmove.bind(this), { passive: true })
    window.removeEventListener('touchend',   this.touchend.bind(this), { passive: true })
  }

  setLocation(event) {
    Logger.log("setting location", event)

    let eventX, eventY
    if(event.touches && event.touches.length > 0) {
      eventX = event.touches[0].pageX
      eventY = event.touches[0].pageY
      this.touching = true
    } else {
      eventX = event.pageX
      eventY = event.pageY
      this.touching = false
    }

    this.storeLocation(eventX, eventY)
  }

  storeLocation(xposition, yposition) {
    this.xlocation = xposition
    this.ylocation = yposition
    this.updateTouchDraggable()
  }

  positionParams(reflex) {
    return [reflex, this.xlocation, this.ylocation, window.innerWidth, window.innerHeight]
  }

  clearClickDisplay() {
    setTimeout(() => {
      this.stimulate('TrackMouse#clear_click')
    }, 500);
  }

  dragstart(event) {
    Logger.log("drag start -- ", event.type)
    this.stimulate('TrackMouse#drag_start')
  }

  drag(event) {
    Logger.log("drag -- ", event.type)
    this.setLocation(event)
    this.stimulate(...this.positionParams('TrackMouse#drag'))
  }

  dragend(event) {
    Logger.log("drag end -- ", event.type)
    this.throttleDrag.cancel()
    this.stimulate('TrackMouse#drag_end')
  }

  mousemove(event) {
    Logger.log("mousemove -- ", event.type)
    this.setLocation(event)
    this.stimulate(...this.positionParams('TrackMouse#mouse_move'))
  }

  mousedown(event) {
    Logger.log("mousedown -- ", event.type)
    this.setLocation(event)
    this.stimulate(...this.positionParams('TrackMouse#mouse_down'))

    this.clearClickDisplay()
  }

  touchstart(event) {
    event.stopPropagation() // prevent window mousedown listener from clicking
    Logger.log("touch start -- ", event.type)
    this.setLocation(event)
    this.stimulate(...this.positionParams('TrackMouse#touch_start'))

    this.clearClickDisplay()
  }

  touchStartDragging(event) {
    event.stopPropagation() // prevent window touchstart listener from clearing drag state
    Logger.log("touch start dragging -- ", event.type)
    this.setLocation(event)
    this.dragging = true
    this.stimulate(...this.positionParams('TrackMouse#touch_start_dragging'))

    this.clearClickDisplay()
  }

  touchmove(event) {
    Logger.log("touchmove -- ", event.type)
    this.setLocation(event)
    this.throttleTouchDisplay(event)
  }

  touchdisplay(event) {
    Logger.log("touch display for therapist -- ", event.type)
    this.stimulate(...this.positionParams('TrackMouse#touch_display'))
  }

  touchend(event) {
    event.stopPropagation() // prevent window touchmove listener from clearing drag state
    event.preventDefault() // prevent window mousedown listener from clicking page
    Logger.log("touch end -- ", event.type)

    this.throttleTouchmove.cancel()
    this.throttleTouchDisplay.cancel()

    setTimeout(() => {
      this.storeLocation(0, 0)
      this.stimulate('TrackMouse#touch_end')
    }, 600);
  }

  dropOnCard(event) {
    Logger.log("track mouse drop on card -- ", event.type, event)
    this.dragging = false
    this.setLocation(event)

    let droppedOn = document.elementFromPoint(this.xlocation, this.ylocation)

    let pageElementsDroppedOn = document.elementsFromPoint(this.xlocation, this.ylocation)

    let droppedOnFromMulti = this.dropOnTargets.find(target => {
      pageElementsDroppedOn.includes(target)
    })

    this.stimulate('TrackMouseReflex#dragging_drop', droppedOn)
  }

  updateTouchDraggable() {
    if(this.hasDraggableCardTarget){
      let element = this.draggableCardTarget

      if(this.dragging && this.touching) {
        let draggingStyle = this.draggingLocation()
        this.setPlaceholder(element)

        element.classList.remove("relative")
        element.classList.add("absolute")
        element.style.left = draggingStyle[0]
        element.style.top = draggingStyle[1]
      } else {
        this.removePlaceholder()

        element.classList.add("relative")
        element.classList.remove("absolute")
        element.style.removeProperty("top");
        element.style.removeProperty("left");
      }
    }
  }

  draggingLocation(){
    let xpx = Math.max(this.xlocation - 20, 0)
    let ypx = Math.max(this.ylocation - 20, 0)

    return [`${xpx}px`, `${ypx}px`]
  }

  removePlaceholder() {
    if(this.hasPlaceholderTarget) {
      this.placeholderTarget.remove()
    }
  }

  setPlaceholder(element) {
    if(this.hasPlaceholderTarget) return

    let placeholder = element.cloneNode(true)

    Array.from(placeholder.querySelectorAll("*")).concat(placeholder).forEach((child) => {
      Array.from(child.attributes).forEach((attr) => {
        if(attr.name.startsWith("data-")) {
          // remove stimulus js hooks from placeholder element
          child.removeAttribute(attr.name)
        }
      })
    })

    placeholder.setAttribute("data-track-mouse-target", "")
    placeholder.setAttribute("data-track-mouse-target", "placeholder")
    placeholder.classList.remove("cursor-pointer")
    placeholder.style.opacity = '0'
    element.insertAdjacentElement('afterend', placeholder);
  }
}
