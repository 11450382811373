import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = [ "submit", "feedback"]
  static values = { prevElement: String }


  enableSubmit(event){
    this.submitTarget.disabled = false
  }

  selectQuestion(event) {
    document.querySelector('.all_questions').classList.remove('bg-gray-200');
    let element = event.target;

    if(this.prevElementValue) {
      let el = document.getElementById(this.prevElementValue);
      el.classList.remove('bg-gray-200');
    }
    this.prevElementValue = element.id
    element.classList.add('bg-gray-200')
    this.stimulate('FeedbackReflex#show_feedback', element)
  }

}
