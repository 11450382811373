import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["item"];
  static values = { url: String, id: String };

  hide() {
    let element = document.getElementById(this.idValue)
    element.removeChild(element.firstElementChild);
  }

  show() {
    let element = document.getElementById(this.idValue)

    fetch(this.urlValue)
      .then((r) => r.text())
      .then((html) => {
        const fragment = document
          .createRange()
          .createContextualFragment(html);

        element.appendChild(fragment);
      });

  }
}
